<template>
  <div id="app">
    <div class="header">
      <div class="top-nav">
        <div class="container">
          <div class="logo">
            <a href="team"><img alt="砾鹰石官网" src="./assets/lyslogo.png" /></a>
          </div>
          <button class="nav-collapse nav-collapse-xs" id="nav-btn">
            <span class="btn-line"></span> <span class="btn-line"></span>
            <span class="btn-line"></span>
          </button>
          <div class="nav nav-show">
            <div class="tbl">
              <div class="tbl-cell">
                <ul class="nav-list1">
                  <!-- 当选中时候 class为active -->
                  <li
                    class="more"
                    :class="
                      $route.path == item.routerpath ? 'router-active' : ''
                    "
                    v-for="(item, index) in menulist"
                    :key="index"
                  >
                    <a class="router-active" @click="torouter(item)">{{
                      item.name
                    }}</a>
                    <ul class="nav-list2">
                      <li
                        v-for="(item2, index2) in item.children"
                        :key="index2"
                      >
                        <a @click="torouter(item2)">{{ item2.name }}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <button class="nav-collapse nav-collapse-1" data-id="222222" id="nav-xs-btn">
              <span class="btn-line"></span> <span class="btn-line"></span>
              <span class="btn-line"></span>
            </button>
            <div class="nav-2" style="opacity: 0.6;"  >
              <div class="nav-top">
                <!-- <div class="back-btn back-btn2"></div>
                <b class="nav-title">一级标题</b> -->
                <button class="nav-collapse nav-collapse-2" data-id="111111"  id="nav2-btn">
                  <span class="btn-line"></span> <span class="btn-line"></span>
                  <span class="btn-line"></span>
                </button>
              </div>
              <div class="content" >
                <!-- <ul class="nav2-list2"></ul> -->
              </div>
            </div>
            <div class="nav-3" >
              <!-- <div class="nav-top">
                <div class="back-btn back-btn3"></div>
                <b class="nav-title">一级标题</b>
                <button class="nav-collapse nav-collapse-2" id="nav2-btn">
                  <span class="btn-line"></span> <span class="btn-line"></span>
                  <span class="btn-line"></span>
                </button>
              </div> -->
              <div class="content">
                <ul class="nav3-list3"></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view />
    <div class="footer">
      <div class="foot">
        <div class="container" style="display: flex; justify-content: center">
          <ul>
            <li class="on">
              <div class="item item-1">
                <div class="title-foot" @click="goabout">
                  <h3>企业简介</h3>
                </div>
                <div class="info-down">
                  <a style="cursor:pointer" @click="goabout">关于我们</a>
                   <a style="cursor:pointer" @click="goabout">企业资质</a></div>
              </div>
            </li>

            <li class="on">
              <div class="item item-1">
                <div class="title-foot" @click="goproduct('one')">
                  <h3>产品中心</h3>
                </div>
                <div class="info-down">
                  <a style="cursor:pointer" @click="goproduct('one')">打印设备</a>
                  <a style="cursor:pointer" @click="goproduct('two')">贴标设备</a>
                  <a style="cursor:pointer" @click="goproduct('three')">互勾智能设备</a>
                  <a style="cursor:pointer" @click="goproduct('four')">互勾社区</a>
                </div>
              </div>
            </li>

            <li class="on">
              <div class="item item-1">
                <div class="title-foot" @click="detailmore('one')">
                  <h3>解决方案</h3>
                </div>
                <div class="info-down">
                  <a style="cursor:pointer" @click="detailmore('one')">智慧社区</a>
                  <a style="cursor:pointer" @click="detailmore('two')">水果行业自动贴标方案</a>
                  <a style="cursor:pointer" @click="detailmore('three')">布匹行业自动贴标方案</a>
                  <a style="cursor:pointer" @click="detailmore('four')">双面胶行业自动贴标方案</a>
                  <a style="cursor:pointer" @click="detailmore('five')">美缝剂行业自动贴标方案</a>
                </div>
              </div>
            </li>

            <!-- <li class="on">
          <div class="item item-1">
            <div class="title-foot">
              <h3>品牌服务</h3>
            </div>
            <div class="info-down">  <a href="index17.html">客户案例</a>  <a href="index18.html">知识百科</a>  <a href="index19.html">常见问题</a>  </div>
          </div>
        </li> -->

            <!-- <li class="on">
          <div class="item item-1">
            <div class="title-foot">
              <h3>新闻资讯</h3>
            </div>
            <div class="info-down">  <a href="index21.html">公司新闻</a>  <a href="index22.html">行业新闻</a>  </div>
          </div>
        </li> -->

            <li class="on">
              <div class="item item-2">
                <div class="title-foot" @click="gocontact()">
                  <h3>联系我们</h3>
                </div>
                <div class="info-down">
                  <p v-if="false">热线电话：<a href="tel:4000099210">400-009-9210</a></p>
                  <p>公司座机：0595-22191300</p>
                  <p>公司邮箱：lishi_qz@163.com</p>
                  <p>公司地址：福建省泉州市鲤城区九一路16号益华商厦9层</p>
                </div>
              </div>
            </li>
            <!-- <li class="on">
          <div class="item item-3">
            <div class="erweima">
              <p>关注微信公众号</p>
             
            </div>
          </div>
        </li> -->
          </ul>
        </div>
      </div>
      <div class="copyright"> 
        <div class="footer">
      <p class="link">
        <a href="https://goudrive.tikfriend.com:89/treaty.html?id=1457905709680300032"
          >《互勾隐私政策》</a
        ><a href="https://goudrive.tikfriend.com:89/treaty.html?id=1457905510480220160"
          >《用户服务协议》</a
        >
      </p>
      <p>
        <span class="copyright"
          >© 2016 - 2022 泉州砾鹰石科技有限公司 版权所有
        </span>
        <span class="icp"
          >|
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >闽ICP备17029570号-1
          </a>
          <img src="../public/image/icp_public.png" alt="闽公网" />
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35050202000187"
            target="_blank"
            >闽公网安备 35050202000187号</a
          ></span>
          <span class="icp">|
          <span class="copyright">增值电信业务经营许可证：  <a :href="require('../public/picture/licence_1.png')" target="_blank">闽B2-20200365，
          </a >跨地区经营许可证编号：<a :href="require('../public/picture/licence_2.png')" target="_blank">B1.B2-20214072</a>
        </span>
        </span>
      </p>
    </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.router-active a {
  color: #2c3e50;
  font-weight: 1000;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.more {
  cursor: pointer;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
import { menulist } from "./static/menu.js";
export default {
  data() {
    return {
      menulist: menulist,
    };
  },
  created(){
    localStorage.setItem("systemIs64", this.isMobile());
    var hrefStr = window.location.href;
    let paramStr = "";
      if (hrefStr.indexOf("?")) {
        paramStr = hrefStr.split("=")[1];
      }
    var u = navigator.userAgent;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端
      var isiOS =
        !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ||
        /(iPhone|iPad|iPod|iOS)/i.test(u) ||
        paramStr == "apple"; // ios终端
      var issafariBrowser =
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent); // 是否是safari浏览器打开
      if (isAndroid) {
        return localStorage.setItem("curSystem", "Android");
      } else if (isiOS) {
        return localStorage.setItem("curSystem", "iOS");
      } else if (issafariBrowser) {
        return localStorage.setItem("curSystem", "iOS");
      } else {
        return localStorage.setItem("curSystem", "other");
      }
  },
  methods: {
    isMobile() {
      return /(arm64|aarch64)/i.test(navigator.platform);
    },
    focusFun(){
      console.log("==========>sdfsdfsdfsdfsdf")
    },
    gocontact(){
      this.$router.push({
        path: "/index4",

      });
    },
    goabout(){
      this.$router.push({
        path: "/index1",

      });
    },
    goproduct(name){
      console.log("=======>", name);
      this.$router.push({
        path: "/index2",
        query: { name: name },
      });
    },
    detailmore(name) {
      console.log("=======>", name);
      this.$router.push({
        path: "/index3",
        query: { name: name },
      });
      // this.$router.push("/index3");
    },
    torouter(item) {
      console.log("item", item);
      this.$router.push(item.routerpath).catch((err) => err);
    },
  },
};
</script>
